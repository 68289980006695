import branchServerClient from "web/core/branchServerClient";
import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import magentoEventInit from "theme/modules/ProductsRecommandations/magentoEventInit";

const removeInvalidCategories = (categories) =>
  categories
    .filter(({ include_in_menu }) => include_in_menu)
    .map((category) => ({
      ...category,
      children: category.children
        ? removeInvalidCategories(category.children)
        : [],
    }));

const EnhanceHeader = ({
  HeaderQuery,
  HeaderUserQuery,
  WishlistHeaderQuery,
  CssOverrideQuery,
  StoreConfigurationQuery,
}) =>
  compose(
    magentoEventInit(),
    graphql(HeaderQuery, {
      name: "data",
    }),
    graphql(CssOverrideQuery, {
      name: "cssOverride",
    }),
    graphql(StoreConfigurationQuery, {
      name: "storeConfigurationQuery",
      props: ({ storeConfigurationQuery }) => ({
        storeConfigurationQuery,
        isKlubEnabled: !!(
          !storeConfigurationQuery?.loading &&
          !storeConfigurationQuery?.error &&
          storeConfigurationQuery?.isKlubEnabled
        ),
      }),
    }),
    withProps((props) => {
      return props.data
        ? {
            ...props,
            data: {
              ...props.data,
              navigationMenu: props.data.navigationMenu
                ? removeInvalidCategories(props.data.navigationMenu)
                : [],
            },
          }
        : props;
    }),
    branchServerClient(
      withProps({
        userData: {},
      }),
      graphql(HeaderUserQuery, {
        name: "userData",
      })
    ),
    branchServerClient(
      withProps({
        cart: {},
      }),
      withProps({
        cart: {},
      })
    ),
    branchServerClient(
      withProps({
        wishlist: {},
      }),
      graphql(WishlistHeaderQuery, {
        name: "wishlist",
      })
    )
  );

export default EnhanceHeader;
