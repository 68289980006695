import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {useLocation} from "react-router"
import { NavLink } from "react-router-dom";
import classNames from "classnames"
import Icon from "theme/components/atoms/Icon/Icon";

const isActiveInMenu = (category, location) => {
  return category.path === location.pathname
    || category.children.some(child => {
      return isActiveInMenu(child, location)
    })
}

const MobileMenuListItem = ({forcedLabel, category, additionalClass, menuType, closeMenu}) => {
  const level = category?.level + (forcedLabel ? 1 : 0);
  const liClasses = classNames("mobile-menu__item", {
    [`level-${level}`]: level,
    'last': true,
    [`menu-${menuType}`]: menuType,
  });


  return <li className={liClasses}>
    <NavLink
      className={additionalClass}
      activeClassName="link--active"
      to={category.path}
      onClick={() => closeMenu()}
    >
      <span className={classNames({
        h4 : category?.level === 3
      })}
        style={
          {
            ...(category?.kpl_title_color && {color: category.kpl_title_color}),
            ...(category?.kpl_menu_color_main && {color: category.kpl_menu_color_main}),
            ...(category?.kpl_menu_background_main_color && {backgroundColor: category.kpl_menu_background_main_color})
          }
        }
      >
      { forcedLabel ?
        <FormattedMessage
          id="layouts.Header.Navigation.Menu.MenuNavigationMobile.SeeAll"
          default="See all"
        />
        : category.name
      }
      </span>
    </NavLink>
  </li>
}

const MobileMenuItem = ({ category, className, setOpenMenu, closeMenu, menuType }) => {
  const location = useLocation();
  const shouldInitializeToOpen = isActiveInMenu(category, location);
  const [isOpened, setOpened] = useState(shouldInitializeToOpen);

  // On component mount set if the menu is open (current path)
  useEffect(() => {
    if (shouldInitializeToOpen && !(category.level === 2 && category.children.length === 0)) {
      setOpenMenu(category.name);
    }
  }, []);

  if (!category.children || category.children.length === 0) {
    return <MobileMenuListItem
      menuType={menuType}
      category={category}
      additionalClass={className}
      closeMenu={closeMenu}
    />
  }

  const liClasses = classNames("mobile-menu__item", {
    "opened": isOpened,
    [`level-${category?.level}`]: category?.level,
    [`menu-${menuType}`]: menuType,
  });


  return (
    <li className={liClasses}>
      <div className={"mobile-menu__item__title"}
           onClick={() => {
             setOpened(!isOpened);
             // Inverted because state will change after.
             setOpenMenu(!isOpened ? category.name : '');
           }}
      >
      { (isOpened && category.level === 2) &&
        <Icon icon={"chevron-left"} title={"close"}/>
      }
        { (category.level !== 2) &&
        <Icon
          icon={isOpened ? "chevron-up" : "chevron-down"}
          title={isOpened ? "close" : "open"}
        />
        }
          <NavLink
              to={category.path}
              onClick={(e) => e.preventDefault()}
          >
        <span className={classNames({
        h4 : category?.level === 3
        })}
        >
        {category.name}
        </span>
          </NavLink>
      </div>
      <MobileMenuList
        seeAllLinkClass={className}
        category={category}
        navigationMenu={category.children}
        isActive={isOpened}
        closeMenu={closeMenu}
      />
    </li>
  );
};

const MobileMenuLinks = ({ menuType, menu, setOpenMenu, closeMenu }) => {
  return (
    menu.map((item) => {
      return <MobileMenuItem
        menuType={menuType}
        key={item.path}
        category={item}
        setOpenMenu={setOpenMenu}
        closeMenu={closeMenu}
      />;
    })
  );
}

const MobileMenuList = ({ category, seeAllLinkClass, navigationMenu, isActive, closeMenu }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const {
    menu_top,
    menu_bottom
  } = navigationMenu && navigationMenu
    .reduce((acc, menu) => {
      let type = menu?.kpl_menu_top_header_visibility ? 'menu_top' : 'menu_bottom';
      acc[type].push(menu);
      return acc;
    }, {'menu_top': [], 'menu_bottom': []});

  return (
    <ul className={classNames("mobile-menu__list", {
      "mobile-menu__list__opened": isActive,
      "mobile-menu__list__selected": openMenu,
    })}>
      { category?.level === 4 &&
        <MobileMenuListItem
          forcedLabel={true}
          category={category}
          additionalClass={seeAllLinkClass}
          closeMenu={closeMenu}
        />
      }
      {
        !!menu_top.length &&
          <MobileMenuLinks
            menuType={"top"}
            menu={menu_top}
            setOpenMenu={setOpenMenu}
            closeMenu={closeMenu}
          />
      }
      {
        !!menu_bottom.length &&
        <MobileMenuLinks
          menuType={"bottom"}
          menu={menu_bottom}
          setOpenMenu={setOpenMenu}
          closeMenu={closeMenu}
        />
      }
    </ul>
  );
};

export default MobileMenuList;
