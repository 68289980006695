import React from "react";
import SearchBar from "theme/modules/Search/SearchBar";

const SearchNavigation = ({ closeSearch }) => {
  return (
    <div className="container">
      <SearchBar closeSearch={closeSearch} />
    </div>
  );
};

export default SearchNavigation;
