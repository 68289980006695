import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { defineMessages, useIntl } from "react-intl";
import compose from "recompose/compose";
import { IconButton } from "theme/components/atoms/Button";
import Link from "theme/components/atoms/Typography/Link";
import SearchNavigation from "./SearchNavigation";
import AccountNavigation from "./AccountNavigation";
import SubSearchNavigation from "./SubSearchNavigation";
import withIsFeatureActive from "web/core/UNSAFE_featureFlags/withIsFeatureActive";
import AccountLoginPopin from "./AccountNavigation/AccountLoginPopin";
import StoreViewSelector from "theme/modules/User/StoreViewSelector";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Breakpoints from "theme/components/atoms/Breakpoints";
import { FormattedMessage } from "react-intl";
import ObfuscatedLink from "../../../components/molecules/ObfuscatedLink/ObfuscatedLink";
import Icon from "theme/components/atoms/Icon";
import { useLocation } from "react-router";

const messages = defineMessages({
  search: {
    id: "layouts.Header.Navigation.search",
    defaultMessage: "Search",
  },
  cart: {
    id: "layouts.Header.Navigation.cart",
    defaultMessage: "My Cart",
  },
  wishlist: {
    id: "layouts.Header.Navigation.wishlist",
    defaultMessage: "My Wishlist",
  },
  cartLength: {
    id: "layouts.Header.Navigation.cartLength",
    defaultMessage: "{quantity, plural, =1 {1 product} other {# products}}",
  },
});

const Navigation = ({
  userData,
  selectMenu,
  renderSubNav,
  cart,
  wishlist,
  renderSearchNav,
  openSearch,
  isSearchOpened,
  isKlubEnabled,
  hasPopupOpened,
}) => {
  const intl = useIntl();
  const closeTimeoutCartRef = useRef(null);
  const [isShownAccount, setShownAccount] = useState(false);
  const [isShownCart, setShownCart] = useState(false);
  const isKlubMember = !!parseInt(userData.me?.klubId);
  const cartHasItems = false;
  const location = useLocation();

  const bagClass = classNames("navigation__item", {
    bag: true,
    minicart__opened: isShownCart,
    full: cartHasItems,
  });

  // Tell to the header if a popup is open to remove header transparency effect.
  useEffect(() => {
    hasPopupOpened?.(isShownCart || isShownAccount);
  }, [hasPopupOpened, isShownCart, isShownAccount]);

  useEffect(() => {
    if (location.search.includes("?q=")) {
      openSearch(true);
    }
  }, []);

  return (
    <nav className="navigation">
      <ul className="navigation__list">
        <li className="navigation__item stores desktop-only">
          <StoreViewSelector openDirection={"right"} />
        </li>

        <li className="navigation__item search">
          <IconButton
            icon="magnifier"
            title={intl.formatMessage(messages.search)}
            onClick={() => openSearch()}
          />
          {renderSearchNav()(
            <SubSearchNavigation
              onClose={() => openSearch(false)}
              isSearchOpened={isSearchOpened}
            >
              <SearchNavigation closeSearch={openSearch} />
            </SubSearchNavigation>
          )}
        </li>
        <li
          className={
            wishlist?.items?.length > 0
              ? "navigation__item wishlist full"
              : "navigation__item wishlist"
          }
        >
          <Link
            to="/user/wishlist"
            title={intl.formatMessage(messages.wishlist)}
          >
            <IconButton
              icon={wishlist?.items?.length ? "filledHeart" : "heart"}
              title={intl.formatMessage(messages.wishlist)}
              dangerouslyIgnoreOnClick
            />
          </Link>
        </li>
        <li
          className={classNames("navigation__item user", {
            "user-opened": isShownAccount,
          })}
          onMouseOver={() => {
            if (closeTimeoutCartRef.current) {
              clearTimeout(closeTimeoutCartRef.current);
              closeTimeoutCartRef.current = null;
              setShownCart(false);
            }
            setShownAccount(true);
          }}
          onMouseLeave={() => {
            setShownAccount(false);
          }}
        >
          <AccountNavigation
            loading={userData?.loading ?? true}
            user={userData?.me}
            selectMenu={selectMenu}
            renderSubNav={renderSubNav}
            isKlubEnabled={isKlubEnabled}
            isKlubMember={isKlubMember}
            klubUserData={userData?.klub?.user}
          />
          <MediaQuery minWidth={Breakpoints.desktop}>
            <AccountLoginPopin user={userData?.me} />
          </MediaQuery>
        </li>
        <li
          className={bagClass}
          onMouseEnter={() => {
            if (closeTimeoutCartRef.current) {
              clearTimeout(closeTimeoutCartRef.current);
              closeTimeoutCartRef.current = null;
            }
            setShownCart(true);
          }}
          onMouseLeave={() => {
            closeTimeoutCartRef.current = setTimeout(() => {
              setShownCart(false);
            }, 1500);
          }}
        >
          <ObfuscatedLink appearance="obfuscatedLink" route="/cart">
            <MediaQuery minWidth={Breakpoints.desktop}>
              {(isDesktop) => (
                <>
                  {isDesktop && (
                    <span className="navigation__item--cart">
                      <span className="navigation__item--cart--title">
                        <FormattedMessage
                          id="layouts.Header.Navigation.shoppingCart"
                          defaultMessage="Cart"
                        />
                      </span>
                    </span>
                  )}

                  {!isDesktop && (
                    <Icon
                      icon={cartHasItems ? "bag-filled" : "bag"}
                      title={intl.formatMessage(messages.cart)}
                      appearance={"header-button"}
                    />
                  )}
                </>
              )}
            </MediaQuery>
          </ObfuscatedLink>
        </li>
      </ul>
    </nav>
  );
};

export default compose(withIsFeatureActive("searchFeature", "search"))(
  Navigation
);
