import React, { useCallback } from "react";
import Link from "theme/components/atoms/Typography/Link";
import LinkList from "theme/components/molecules/LinkList";
import Image from "theme/components/atoms/Image";
import classNames from "classnames";
import Wysiwyg from "theme/modules/WysiwygV2/Wysiwyg";
import { compose } from "recompose";
import CategoriesMenuWidgetQuery from "./CategoriesMenuWidgetQuery.gql";
import EnhanceCategoriesMenuWidget from "./EnhanceCategoriesMenuWidget";

const PRIMARY_MENU_LENGTH = 3;

/**
 * Display an array of menu links.
 *
 * @param menu
 * @returns {*}
 * @constructor
 */
const CategoryMenuLinks = ({ menu, showChildren }) => {
  const getLinkStyle = (category) => ({
    ...(category?.kpl_title_color && {
      color: category.kpl_title_color,
    }),
    ...(category?.kpl_menu_color_main && {
      color: category.kpl_menu_color_main,
    }),
    ...(category?.kpl_menu_background_main_color && {
      backgroundColor: category.kpl_menu_background_main_color,
    }),
  });

  const extraItems = menu.slice(PRIMARY_MENU_LENGTH).map((category) => (
    <LinkList
      key={category.id}
      title={
        <Link to={category.path} style={getLinkStyle(category)}>
          {category.name}
        </Link>
      }
    />
  ));

  const primaryMenu = menu.slice(0, PRIMARY_MENU_LENGTH);

  const getChildrenList = useCallback(
    ({ kpl_visible_in_megamenu, children }) => {
      if (showChildren && kpl_visible_in_megamenu) {
        return children.filter(
          ({ kpl_visible_in_megamenu }) => kpl_visible_in_megamenu
        );
      }

      return [];
    },
    [showChildren]
  );

  return (
    <div className="categories-menu--link-list">
      {primaryMenu.map((category, index) => (
        <LinkList
          key={category.id}
          title={
            <Link to={category.path} style={getLinkStyle(category)}>
              {category.name}
            </Link>
          }
          list={getChildrenList(category)}
          extraItems={index === primaryMenu.length - 1 ? extraItems : null}
          cols={2}
        />
      ))}
    </div>
  );
};

const CategoriesMenu = ({
  category,
  // Enhanced properties.
  loading,
  cmsContent,
}) => {
  if (loading) return null;

  const { menu_top, menu_bottom } =
    category?.children &&
    category.children.reduce(
      (acc, menu) => {
        let type = menu?.kpl_menu_top_header_visibility
          ? "menu_top"
          : "menu_bottom";
        acc[type].push(menu);
        return acc;
      },
      { menu_top: [], menu_bottom: [] }
    );

  return (
    <div className="container">
      <div
        className={classNames("categories-menu", {
          "categories-menu--link-image": !cmsContent,
          "categories-menu--link-only": !category?.image,
          "categories-menu--widget": cmsContent,
        })}
      >
        <div className="categories-menu--container">
          {cmsContent && (
            <Wysiwyg
              content={cmsContent}
              containerClass="categories-menu--container--wysiwyg"
            />
          )}
          {!cmsContent && (
            <>
              <div className="categories-menu--container--entries">
                {!!menu_top.length && (
                  <div className="categories-menu--top">
                    <CategoryMenuLinks menu={menu_top} />
                  </div>
                )}
                {!!menu_bottom.length && (
                  <div className="categories-menu--bottom">
                    <CategoryMenuLinks menu={menu_bottom} showChildren />
                  </div>
                )}
              </div>
              {category?.image && (
                <div className="categories-menu--container--illustration">
                  <Image
                    src={category.image}
                    alt={category.name}
                    format="catMenu"
                    appearance="full"
                    cover
                    dangerouslyDisableLazyLoad
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  EnhanceCategoriesMenuWidget({
    CategoriesMenuWidgetQuery,
  })
)(CategoriesMenu);
