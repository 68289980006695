import React, { useEffect } from "react";
import EnhanceSearchBar from "./EnhanceSearchBar";
import Autocomplete from "theme/components/organisms/Autocomplete/Autocomplete";
import SearchBarQuery from "./SearchBarQuery.gql";
import WysiwygSearchBar from "./WysiwygSearchBar.gql";
import SearchBarResults from "./SearchBarResults";
import useSearchBar from "./useSearchBar";
import SearchBarForm from "./SearchBarForm";
import useSearchList from "./useSearchList";
import CmsBlock from "theme/modules/CmsBlock";

const SearchBar = ({
  search,
  setSearch,
  data,
  loadingQuery,
  error,
  closeSearch,
  setSearchProducts,
  me,
}) => {
  const {
    contentRef,
    searchUrl,
    searchTarget,
    onSubmit,
    onChange,
    showAutocompleteResults,
    isOpen,
    setIsOpen,
    selected,
    onSelect,
    onLoadMore,
  } = useSearchBar({ search, setSearch, data });
  const { products } = useSearchList({ search, data });

  useEffect(() => {
    data?.searchSuggestions?.products.length > 0 &&
      setSearch((prevState, props) => ({ ...prevState, canUpdate: false }));
  }, [setSearch, data?.searchSuggestions?.products]);

  const promptEmptyBlock =
    !me.loading &&
    me.cmsBlock.find((el) => el?.identifier === "search-before-result");
  const noResultBlock =
    !me.loading &&
    me.cmsBlock.find((el) => el?.identifier === "search-no-result");
  return (
    <div className="search-box" ref={contentRef}>
      <div className="search-box__form">
        <SearchBarForm
          search={search}
          searchTarget={searchTarget}
          data={data}
          selected={selected}
          isExpanded={showAutocompleteResults}
          onSubmit={onSubmit}
          onChange={onChange}
          closeSearch={closeSearch}
          setSearch={setSearch}
        />
      </div>
      {/* {loadingQuery && <SearchBarResultsLoading />} */}
      {!loadingQuery && !search?.search && promptEmptyBlock && (
        <div className={"search-box__replaced-content"}>
          <CmsBlock cmsBlock={promptEmptyBlock} />
        </div>
      )}
      {data?.searchSuggestions?.products.length === 0 &&
        search?.search !== "" &&
        noResultBlock && (
          <div className={"search-box__replaced-content"}>
            <CmsBlock cmsBlock={noResultBlock} />
          </div>
        )}
      {isOpen && (
        <Autocomplete onClose={() => setIsOpen(false)}>
          <SearchBarResults
            loading={loadingQuery ?? false}
            error={error}
            search={search}
            searchUrl={searchUrl}
            results={products}
            selected={selected}
            onChange={onChange}
            setSearch={setSearch}
            onLoadMore={onLoadMore}
            onSelect={onSelect}
            hitsPerPage={16}
            setSearchProducts={setSearchProducts}
          />
        </Autocomplete>
      )}
    </div>
  );
};

export default EnhanceSearchBar({
  SearchQuery: SearchBarQuery,
  WysiwygSearchBar,
})(SearchBar);
