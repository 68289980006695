import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import Form from "theme/components/atoms/Form/Form";
import FormItem from "theme/components/molecules/Form/Item";
import { SearchSortSelect } from "theme/components/atoms/Form/Input";

const messages = defineMessages({
  sortTitle: {
    id: "modules.Layer.SearchSort.sortLabel",
    defaultMessage: "Sort by",
  },
  priceAsc: {
    id: "modules.Layer.SearchSort.priceAsc",
    defaultMessage: "Price ascending",
  },
  priceDesc: {
    id: "modules.Layer.SearchSort.priceDesc",
    defaultMessage: "Price descending",
  },
  relevance: {
    id: "modules.Layer.SearchSort.relevance",
    defaultMessage: "Relevance",
  },
});

const SearchSort = (props) => {
  return (
    <Form onChange={props.onChange}>
      <FormItem
        label={props.intl.formatMessage(messages.sortTitle)}
        name="sort"
      >
        <SearchSortSelect
          name="sort"
          id="sort"
          placeholder={props.intl.formatMessage(messages.relevance)}
          options={[
            {
              label: props.intl.formatMessage(messages.priceAsc),
              name: "price_asc",
            },
            {
              label: props.intl.formatMessage(messages.priceDesc),
              name: "price_desc",
            },
          ]}
          value={(props.initialValue && props.initialValue.sort) || undefined}
        />
      </FormItem>
    </Form>
  );
};

export default injectIntl(SearchSort);
