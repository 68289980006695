import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router";
import classNames from "classnames";
import Link from "theme/components/atoms/Typography/Link";
import SubNavigation from "../SubNavigation";
import CategoriesMenu from "./CategoriesMenu";

const isActive = (category, location) => {
  return location.pathname.indexOf(category.path) === 0;
};
const MainCategoryElement = ({
  category,
  selectCategory,
  closeCategory,
  renderSubNav,
  selectedMenu,
  active,
}) => {
  const location = useLocation();
  const isCurrentPath = isActive(category, location);
  const menuActive = useRef(null);
  const isLinkWithoutChildren =
    category.children.length === 0 &&
    category.kpl_navigation_preview?.childNodes?.length === 0;

  useEffect(() => {
    menuActive.current = !!selectedMenu;
  }, [selectedMenu]);

  const linkClassName = classNames("menu-links", {
    active: (!selectedMenu && isCurrentPath) || selectedMenu === `category_${category.id}` ,
    inverted: !!category?.kpl_menu_color_inverted,
  });

  return (
    <>
      <div
        className={linkClassName}
        onMouseEnter={() => {
          !isLinkWithoutChildren && selectCategory();
          isLinkWithoutChildren && closeCategory();
        }}
      >
        {isLinkWithoutChildren ? (
          <Link
            to={category.path}
            {...(!category?.kpl_menu_color_inverted && {
              style: {
                ...(category?.kpl_title_color && {
                  color: category.kpl_title_color,
                }),
                ...(category?.kpl_menu_color_main && {
                  color: category.kpl_menu_color_main,
                }),
                ...(category?.kpl_menu_background_main_color && {
                  "background-color": category.kpl_menu_background_main_color,
                }),
              },
            })}
          >
            {category.name}
          </Link>
        ) : (
          <Link to={category.path}>{category.name}</Link>
        )}
      </div>
      {renderSubNav(
        <SubNavigation
          onClose={() => closeCategory()}
          onEnter={() => selectCategory()}
          selectedMenu={menuActive.current}
          active={active}
        >
          <CategoriesMenu category={category} />
        </SubNavigation>
      )}
    </>
  );
};

const MenuMainCategories = ({
  data,
  selectMenu,
  renderSubNav,
  selectedMenu,
}) => {
  const closeTimeoutRef = useRef(null);
  const closeTimeoutEnter = useRef(null);
  const [active, setActive] = useState(true);

  const closeCategory = () => {
    if (closeTimeoutEnter.current) {
      clearTimeout(closeTimeoutEnter.current);
    }
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }

    closeTimeoutEnter.current = setTimeout(() => {
      setActive(false);
    }, 200);
    closeTimeoutRef.current = setTimeout(() => {
      selectMenu(null);
    }, 400);
  };

  const selectCategory = (categoryId) => {
    if (closeTimeoutEnter.current) {
      clearTimeout(closeTimeoutEnter.current);
    }
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setActive(true);
    categoryId && selectMenu(`category_${categoryId}`);
  };

  return data.navigationMenu && data.navigationMenu.length ? (
    <nav className="menu-main-categories">
      <ul
        className="menu-main-categories__list"
        onMouseEnter={() => {
          if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
          }
          if (closeTimeoutEnter.current) {
            clearTimeout(closeTimeoutEnter.current);
          }
        }}
        onMouseLeave={() => closeCategory()}
      >
        {data.navigationMenu.map((category) => (
          <li className="menu-main-categories__item" key={category.id}>
            <MainCategoryElement
              category={category}
              selectedMenu={selectedMenu}
              active={active}
              selectCategory={() => selectCategory(category.id)}
              closeCategory={() => closeCategory()}
              renderSubNav={renderSubNav(`category_${category.id}`)}
            />
          </li>
        ))}
      </ul>
    </nav>
  ) : null;
};
export default MenuMainCategories;
