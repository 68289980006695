import React from "react";
import { withRouter, Route } from "react-router";
import Header from "./Header";
import Footer from "./Footer";
import GenericLayout from "./GenericLayout";
import Modal from "theme/components/organisms/Modal";
import { ModalProvider } from "./ModalContext";

const Layout = (props) => {
  const kapTopNoticeBlock = props.categories?.cmsBlocks?.find(
    (elem) => elem.identifier === "kap-top-notice"
  );
  const header = <Header kapTopNoticeBlock={kapTopNoticeBlock} />;
  const footer = (
    <Footer
      categories={props.categories}
      accountDetails={props.accountDetails}
      contact={props.contact}
    />
  );

  const children = (
    <div className={"mdl-layout__content"}>{props.children}</div>
  );

  if (process.env.SERVER) {
    return (
      <GenericLayout
        location={props.location}
        header={header}
        footer={footer}
        withKapTopNotice={kapTopNoticeBlock ? true : false}
      >
        {children}
      </GenericLayout>
    );
  }

  return (
    <GenericLayout location={props.location} header={header} footer={footer}>
      <Route
        location={props.isModal ? props.previousLocation : props.location}
        render={() => children}
      />
      {props.isModal ? (
        <Modal
          appearance={props.modalAppearance}
          onRequestClose={() => props.history.push(props.previousLocation)}
          isOpen
        >
          <ModalProvider isModal={true}>{props.children}</ModalProvider>
        </Modal>
      ) : null}
    </GenericLayout>
  );
};

export default withRouter(Layout);
