import React from "react";
import { FormattedMessage } from "react-intl"
import AccountIcon from "./AccountIcon";
import ObfuscatedLink from "../../../../components/molecules/ObfuscatedLink/ObfuscatedLink";
import ApmSticker from "theme/modules/ApmSticker";

const AccountNavigation = ({ loading, user, selectMenu, isKlubEnabled, isKlubMember }) => {
  const isLoggedIn = (user?.id ?? null) !== null
  const isKlub = isKlubEnabled && isKlubMember

  return (
    <ObfuscatedLink 
      route="/user"
      appearance="obfuscatedLink"
    >
      <span className="navigation__item--user">
      <AccountIcon
        loading={loading}
        user={user}
        onUserMenu={() => selectMenu("account")}
      />
        <span className="navigation__item--user--info">
          {(isLoggedIn && !isKlub) &&
            <span className="navigation__item--user--info--name">
              <FormattedMessage
                id="layouts.Header.Navigation.welcomeUser"
                defaultMessage="Welcome {firstname}"
                values={{firstname: user?.firstname}}
              />
            </span>
          }

          {(isLoggedIn && isKlub) &&
          <>
            <span className="navigation__item--user--info--name">
              <FormattedMessage
                id="layouts.Header.Navigation.welcomeKlubUser"
                defaultMessage="{firstname}"
                values={{firstname: user?.firstname}}
              />
            </span>

            <div className="navigation__item--user--info--points">
              <ApmSticker
                customerEmail={user.email}
                customerClientId={user.id}
                customerFirstname={user.firstname}
                customerLastname={user.lastname}
              />
            </div>
          </>
          }
        </span>
      </span>
    </ObfuscatedLink>
  );
};

export default AccountNavigation;
