import React from "react";
import { FormattedMessage } from "react-intl";
import ResultsBlock from "theme/components/molecules/ResultsBlock";
import AutocompleteResults, {
  AutocompleteOption,
} from "theme/components/organisms/Autocomplete/AutocompleteResults";
import ProductResultItem from "./ProductResultItem";
import SearchBarResultsLoading from "theme/modules/Search/SearchBar/SearchBarResults/SearchBarResultsEmptyLoading";
import { compose } from "recompose";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import SearchSort from "./SearchSort/SearchSort";

const ProductsResults = (props) => {
  console.log(props.search);
  const onChange = (data) => {
    props.onChange({
      ...props.search,
      sort: data?.sort
        ? data?.sort?.toLocaleLowerCase()
        : props.search.sort.sort,
      from: 0,
      showMore: false,
      // sortValue: searchSort?.name,
      canUpdate: true,
    });
  };
  return (
    <ResultsBlock
      title={
        props.loading ? (
          <SearchBarResultsLoading />
        ) : (
          <FormattedMessage
            id="modules.Search.SearchBar.ProductResults.title"
            defaultMessage="Products"
            values={{ hits: props.total, searchQuery: props.search?.search }}
          />
        )
      }
      sort={
        <SearchSort
          onChange={onChange}
          loading={false}
          initialValue={{ sort: props.search.sortValue }}
        />
      }
      className="products-results"
    >
      <AutocompleteResults>
        {props.results.map((result, index) => (
          <AutocompleteOption
            key={index}
            id={`autocomplete-product-${result.sku}`}
            path={result.path}
            selected={props.selected}
            onSelect={props.onSelect}
          >
            <ProductResultItem
              key={index}
              result={result}
              index={index}
              enableBadges
            />
          </AutocompleteOption>
        ))}
      </AutocompleteResults>
    </ResultsBlock>
  );
};

export default compose(withTrackPage("Search"))(ProductsResults);
