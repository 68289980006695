import { compose } from "recompose";
import { graphql } from "react-apollo";

/**
 * Load CMS content for menu to ensure using category cache.
 *
 * @param CategoriesMenuWidgetQuery
 * @returns {*}
 * @constructor
 */
const EnhanceCategoriesMenuWidget = ({
  CategoriesMenuWidgetQuery
}) =>
  compose(
    graphql(CategoriesMenuWidgetQuery, {
      options: ({ category: { id } }) => ({
        variables: {
          id,
        }
      }),
      props: ({ data }) => ({
        loading: data.loading,
        w: data,
        cmsContent: !data.loading &&
          !data.error &&
          !!data.category?.kpl_navigation_preview?.raw &&
          data.category?.kpl_navigation_preview,
      })
    }),
  );

export default EnhanceCategoriesMenuWidget;