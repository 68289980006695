import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router";

const useSubSearchNavigation = () => {
  const subSearchRef = useRef();
  const location = useLocation();
  const html = !process.env.SERVER && document.querySelector("html");

  const [isSearchOpened, setIsSearchOpened] = useState(false);

  const openSearch = () => {
    !isSearchOpened &&
      html &&
      setTimeout(function () {
        html.style.overflow = "hidden";
        html.classList.add("no-overflow");
      }, 10);
    if (isSearchOpened)
      document.getElementsByClassName(
        "navigation__sub__search--opened"
      )[0].style.opacity = 0;
    setTimeout(function () {
      setIsSearchOpened(!isSearchOpened);
    }, 10);
  };

  const renderSearchNav = () =>
    isSearchOpened
      ? (element) => createPortal(element, subSearchRef.current)
      : () => {};

  useEffect(() => {
    !location.search && setIsSearchOpened(false);
  }, [location.search]);

  useEffect(() => {
    !isSearchOpened && html.classList.remove("no-overflow");
  }, [isSearchOpened, html.classList]);

  return [
    subSearchRef,
    renderSearchNav,
    openSearch,
    isSearchOpened,
    setIsSearchOpened,
  ];
};

export default useSubSearchNavigation;
