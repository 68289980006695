import { compose, withState } from "recompose";
import { graphql } from "react-apollo";

const homogeneizeSuggestions = (data) => {
  const searchSuggestions = data.kaporalSearch && {
    ...data.kaporalSearch,
    products: data.kaporalSearch.products,
    total: data.kaporalSearch.total || 0,
  };

  return {
    ...data,
    searchSuggestions,
  };
};

const EnhanceSearchBar = ({ SearchQuery, WysiwygSearchBar }) =>
  compose(
    withState("search", "setSearch", {
      from: 0,
      showMore: false,
      canUpdate: true,
      search: "",
      initLoad: true,
      sort: "relevance",
    }),
    graphql(WysiwygSearchBar, {
      name: "me",
    }),
    graphql(SearchQuery, {
      skip: ({ search }) =>
        !search.search ||
        !search.canUpdate ||
        (search.from === 0 && search.showMore),
      options: ({ search }) => {
        return {
          variables: {
            query: search.search,
            size: search.initLoad && search.from ? 16 + search.from : 16,
            from: search.initLoad ? 0 : search.from,
            sort: search.sort ?? "relevance",
          },
        };
      },
      props: ({ data, ownProps }) => {
        return {
          loadingQuery: data.loading,
          error: data.error,
          data: homogeneizeSuggestions(data),
        };
      },
    })
  );

export default EnhanceSearchBar;
