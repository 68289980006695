import React, { useEffect, useRef, useCallback } from "react";
import classNames from "classnames";
import ProductsResults from "../ProductsResults";
import Stack from "theme/components/atoms/Layout/Stack";
import Spinner from "theme/components/atoms/Spinner";

const SearchBarResults = (props) => {
  const hasProducts = props.results?.products?.length > 0;
  const observerTarget = useRef(null);

  const loadMore = useCallback(() => {
    if (
      !props.search?.canUpdate &&
      props.results.products.length < props.results.total
    ) {
      props.onLoadMore({
        ...props.search,
        from: props.search.from + props.hitsPerPage,
        showMore: true,
        canUpdate: true,
      });
    }
  }, [props.search, props.results, props.onLoadMore, props.hitsPerPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [loadMore]);

  return (
    <div className={classNames("searchbar-results")}>
      <Stack size="2">
        <div className="searchbar-results__results">
          {hasProducts && (
            <div key="products" className="searchbar-results__element">
              <ProductsResults
                results={props.results.products}
                total={props.results.total}
                query={props.results.query}
                selected={props.selected}
                onSelect={props.onSelect}
                setSearch={props.setSearch}
                search={props.search}
                onChange={props.onChange}
                loading={props.loading ?? false}
              />
            </div>
          )}
          {(props.loading ||
            (hasProducts &&
              props.results.products.length < props.results.total)) && (
            <div ref={observerTarget} className="searchbar-results__loader">
              <Spinner />
            </div>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default SearchBarResults;
