import { useCallback, useRef, useState, useEffect, useMemo } from "react";
import useDebouncedCallback from "theme/components/helpers/useDebouncedCallback";
import useAutocomplete from "theme/components/organisms/Autocomplete/useAutocomplete";
import useSearchBarNavigation from "theme/modules/Search/SearchBar/useSearchBarNavigation";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

const useSearchBar = ({ search, setSearch, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const searchState = useRef();

  const createURL = (state) => `?${qs.stringify(state)}`;

  const valuesFromQuery = useMemo(() => {
    const { q, sort, from } = qs.parse(location.search.slice(1));

    let searchData = {
      from: 0,
      showMore: !!from,
      canUpdate: true,
      search: q,
      sort: sort,
    };

    if (from) {
      searchData = {
        ...searchData,
        from: parseInt(from),
      };
    }

    return searchData;
  }, [location.search]);

  useEffect(() => {
    if (
      !searchState.current ||
      JSON.stringify(searchState.current) !== JSON.stringify(valuesFromQuery)
    ) {
      searchState.current = valuesFromQuery;

      setSearch({ ...valuesFromQuery, initLoad });
      setInitLoad(false);
    }
  }, [valuesFromQuery, setSearch]);

  const contentRef = useRef();
  const [selected, onSelect] = useAutocomplete(
    contentRef,
    isOpen,
    data?.searchSuggestions?.query,
    "all",
    useCallback(() => {
      setSearch({
        ...search,
        search: "",
        from: 0,
        size: 12,
        showMore: false,
        canUpdate: true,
        initLoad: false,
      });
      setIsOpen(false);
    }, [setSearch, search])
  );

  const { searchUrl, searchTarget, onSubmit } = useSearchBarNavigation(search);
  const searchQuery = useCallback(
    (data) => {
      let state = {
        q: data?.search,
      };
      if (data?.from) {
        state.from = data?.from;
      }
      if (data?.sort) {
        state.sort = data?.sort;
      }

      const searchParams = createURL(state);

      if (location.search !== searchParams) {
        history.push({
          pathname: location.pathname,
          search: state ? searchParams : "",
        });
      }
    },
    [location, history]
  );

  const setSearchDebounced = useDebouncedCallback(searchQuery, 300);
  const onChange = (data) => {
    const forceResetFrom = search.initLoad ? false : true;
    const searchData = {
      ...search,
      showMore: false,
      canUpdate: true,
      ...data,
      initLoad: false,
    };
    if (forceResetFrom) {
      searchData.from = "";
    }
    setSearchDebounced(searchData);
    setIsOpen(data?.search?.length > 0);
  };

  const onLoadMore = (data) => {
    setSearchDebounced({ ...data, initLoad: false });
  };

  const showAutocompleteResults = data && !data.loading;

  return {
    contentRef,
    searchUrl,
    searchTarget,
    onSubmit,
    onChange,
    showAutocompleteResults,
    isOpen,
    setIsOpen,
    selected,
    onSelect,
    onLoadMore,
  };
};

export default useSearchBar;
